@font-face {
    font-family: 'VT323'; /* 400 */
    src: url('assets/fonts/VT323-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Milky-Nice'; /* 600 */
    src: url('assets/fonts/MilkyNice-Clean.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

:root {
    --toastify-icon-color-error: #ff0000 !important;
    --toastify-color-progress-error: #ff0000 !important;
    --toastify-color-progress-dark: #ff7619 !important;
}

* {
    box-sizing: border-box;
}
html {
    overflow-x: hidden;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;
    background: black !important;
    min-height: 100vh;

    cursor: url('assets/img/CustomCursor-Default.png') 10 11,  auto;
}

body::-webkit-scrollbar {
    width: 9.69px;
}

body::-webkit-scrollbar-track {
    background: #323232;
}

body::-webkit-scrollbar-thumb {
    background: #ffffff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.Toastify__toast {
    background: #121212;
    box-shadow: 4px 4px 30px rgba(255, 255, 255, 0.08);
    border-radius: 0 !important;
}

.Toastify__toast .Toastify__toast-body {
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
}

.Toastify__toast .Toastify__toast-icon {
    margin-right: 16px !important;
}

a, button {
    cursor: url('assets/img/CustomCursor-Click.png') 10 11, auto;
}